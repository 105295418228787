import React, { useState, useEffect } from "react"
import Glide, {
  Swipe,
  Images,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm"

import Arrow from "./arrow"
import Image from "./image"

// glide slider styles
import "@glidejs/glide/src/assets/sass/glide.core.scss"

// custom slider styles
import s from "./slider.module.scss"

const defaultOptions = {}

const Slider = ({
  slides = [],
  maxHeight = "42rem",
  color = "white",
  options = defaultOptions,
  draggingHandler = null,
  children = [],
}) => {
  const [glide] = useState(
    new Glide(".glide", {
      ...options,
    })
  )

  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    // Add slide event listeners
    glide.on("move", () => setActiveIndex(glide.index))

    glide.mount({ Swipe, Images, Breakpoints })
    if (draggingHandler) {
      glide.on("swipe.start", () => draggingHandler(true))
      glide.on("swipe.end", () => draggingHandler(false))
    }

    // Cleanup when unmounting component
    return () => glide && glide.destroy()
  }, [glide])

  slides = children.length ? [...children] : slides

  return (
    <div className={[s.slider_container, "glide"].join(" ")}>
      <div className="glide__track" data-glide-el="track">
        <ul style={{ maxHeight }} className="glide__slides">
          {children.length
            ? children
            : slides.map((s, i) => (
                <li
                  key={i}
                  style={{ marginBottom: 0 }}
                  className="glide__slide"
                >
                  <Image
                    style={{ maxHeight }}
                    className="glide__slide"
                    data={s}
                  />
                </li>
              ))}
        </ul>
      </div>
      <div className={s.slider_controls}>
        <button
          disabled={glide.index === 0}
          className={[s.slider_arrow, s.left].join(" ")}
          onClick={_ => glide.go("<")}
        >
          <Arrow color={color} />
        </button>
        <div className={s.slider_bullets}>
          {slides.map((el, index) => (
            <button
              key={index}
              className={[s.bullet, index === activeIndex && s.active].join(
                " "
              )}
              onClick={_ => glide.go(`=${index}`)}
            >
              <span></span>
            </button>
          ))}
        </div>
        <button
          disabled={glide.index >= slides.length - glide.settings.perView}
          className={[s.slider_arrow].join(" ")}
          onClick={_ => glide.go(">")}
        >
          <Arrow color={color} />
        </button>
      </div>
    </div>
  )
}

export default Slider
